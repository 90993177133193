var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"material-map ui"},[_c('Sidebar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('Locale',{attrs:{"path":"map.mint_selection"}})]},proxy:true},{key:"tools",fn:function(){return [_c('list-selection-tools',{attrs:{"hideSelectAllButton":true,"allSelected":_vm.allMintsSelected,"noneSelected":_vm.mint_locations_mixin_noneSelected},on:{"select-all":_vm.selectAllMints,"unselect-all":_vm.clearMintSelection}})]},proxy:true}])},[_c('mint-list',{attrs:{"items":_vm.mintList,"selectedIds":_vm.selectedMints},on:{"selectionChanged":(val) => _vm.mintSelectionChanged(val, { preventUpdate: true })}})],1),_c('div',{staticClass:"center-ui center-ui-top"},[_c('map-toolbar',{attrs:{"filtersActive":_vm.filtersActive},on:{"reset-filters":_vm.resetFilters}})],1),_c('div',{staticClass:"center-ui center-ui-center"}),_c('div',{staticClass:"center-ui center-ui-bottom"},[_c('TimelineSlideshowArea',{ref:"timeline",attrs:{"map":_vm.map,"timelineFrom":_vm.timeline.from,"timelineTo":_vm.timeline.to,"timelineValue":_vm.raw_timeline.value,"timelineActive":_vm.timelineActive,"shareLink":_vm.getShareLink(),"timelineName":"additional-map"},on:{"input":_vm.timelineChanged,"toggle":_vm.timelineToggled},scopedSlots:_vm._u([{key:"background",fn:function(){return [_c('canvas',{ref:"timelineCanvas",attrs:{"id":"timeline-canvas"}})]},proxy:true}])})],1),_c('Sidebar',{ref:"catalogSidebar",attrs:{"side":"right"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('Locale',{attrs:{"path":"map.type_filter"}})]},proxy:true}])},[_c('div',{staticClass:"padding-box"},[_c('catalog-filter',{ref:"catalogFilter",attrs:{"initData":_vm.catalog_filter_mixin_initData,"forceAll":true,"pageInfo":_vm.pageInfo,"exclude":[
          'mint',
          'yearOfMint',
          'ruler',
          'buyid',
          'caliph',
          'treadwellId',
          'projectId',
          'heir'
        ],"overwriteFilters":_vm.overwriteFilters,"typeBody":"\n                      id\n                      projectId\n                      yearOfMint\n                      material {\n                        id\n                        name\n                        color\n                      }\n                      mint {\n                        id\n                        name\n                        location \n                        uncertain\n                        province {\n                          id\n                          name\n                        }\n                      }\n                      excludeFromTypeCatalogue\n                      "},on:{"loading":_vm.setLoading,"update":_vm.dataUpdated,"dynamic-change":_vm.recalculateCatalogSidebar,"toggled":_vm.save,"error":(e) => _vm.$store.commit('printError', e)}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }