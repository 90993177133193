<template>
    <div class="page">
        <section class="content-wrapper">
            <slot />
        </section>
    </div>
</template>

<script>
export default {

};
</script>

<style lang='scss' scoped></style>