<template>
    <div class="spacer"></div>
</template>

<script>
    export default {
    
    };
</script>

<style lang='scss' scoped>
    .spacer {
        flex: 1;
    }
</style>