var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"locale-link",attrs:{"to":{
        name: 'Locale',
        force: true,
        params: {
            lang: _vm.lang,
            path: _vm.path
        }
    }}},[_c('div',{on:{"mouseenter":() => _vm.mouseover = true,"mouseleave":() => _vm.mouseover = false,"click":function($event){$event.stopPropagation();}}},[_c('EarthIcon',{class:{ active: _vm.active },attrs:{"size":10}}),(_vm.mouseover)?_c('Tooltip',[_vm._v(" "+_vm._s(_vm.path)+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }