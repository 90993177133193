<template>
  <div></div>
</template>

<script>
import MapMixin from './mixins/map';
import { concentricCircles } from '../../maps/graphics/ConcentricCircles';


export default {
  mixins: [MapMixin],
  mounted() {



  }
};
</script>