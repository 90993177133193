
import ComponentDebugPage from "@/components/page/ComponentDebugPage.vue";


const locationInput = {

}

export const componentTestRoutes = [{
    path: "/test/",
    name: "Test",
    component: ComponentDebugPage,
},
]