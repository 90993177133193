import { render, staticRenderFns } from "./CreateTypePage.vue?vue&type=template&id=c970419a&scoped=true&"
import script from "./CreateTypePage.vue?vue&type=script&lang=js&"
export * from "./CreateTypePage.vue?vue&type=script&lang=js&"
import style0 from "./CreateTypePage.vue?vue&type=style&index=0&id=c970419a&prod&lang=scss&"
import style1 from "./CreateTypePage.vue?vue&type=style&index=1&id=c970419a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c970419a",
  null
  
)

export default component.exports